import './App.css';
import Layout from './components/Layout';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Bailiffs from './components/bailiffs/Bailiffs';
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
import { Container, Grid} from 'semantic-ui-react';
import logo from "./e-justice-logo.png";


function App() {

  const { t } = useTranslation();

  return (
    <div className="App"> 
      <Container className="header">
        <Grid stackable>
          <Grid.Row className="logo-area">
            <Grid.Column width={11}>
              <img src={logo} alt="e-justice portal" className="logo" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container> 
      <Grid>
        <Grid.Row className="intro-header">
          <Grid.Column width={16}>
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h1>{t('main.title')}</h1>
                    <div className="subtitle">
                      <p>{t('main.siteDescription')}</p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>  
      <Container>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/bailiffs/:country" element={<Bailiffs/>}></Route>
          </Route>
        </Routes>
      </Container>  
      <div id="footer-extra">
        <Container>
          <a href="#" title="Report a technical/content problem or give feedback on this page">Report a technical/content problem or give feedback on this page</a>
        </Container>
      </div>
      <div id="footer-links">
        <Container>
          <a target="_blank" href="https://european-union.europa.eu/cookies_en"><span>Cookies</span></a>
            <a href="https://e-justice.europa.eu/contentPresentation.do?plang=en&amp;idTaxonomy=365" title="Legal notice">Legal notice</a>
            <a href="https://e-justice.europa.eu/contentPresentation.do?plang=en&amp;idTaxonomy=38570" title="Privacy policy">Privacy policy</a>
            <a href="https://e-justice.europa.eu/contentPresentation.do?plang=en&amp;idTaxonomy=37837" title="Brexit notice">Brexit notice</a>
            <a href="https://e-justice.europa.eu/contactUs.do?plang=en&amp;feedbackEmail=2" title="Contact">Contact</a>
            <a href="https://e-justice.europa.eu/sitemapManagement.do?plang=en" title="Sitemap">Sitemap</a>
        </Container>
      </div>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )
}
