import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getall, default_client, gr_client, ee_client } from '../../api/axiosConfig';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Divider, Button, Grid, Header, Flag, Accordion, Icon, Input, Pagination, Popup, Message, List, Table, Card, CardContent, TableHeaderCell, TableRow, TableHeader, TableBody, TableCell, Label, Search, TableFooter, Segment } from 'semantic-ui-react'
import './Bailiffs.css';
import i18next, { t } from "i18next";
import _ from 'lodash';
import SearchButtons  from "../searchButtons/SearchButtons";

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'MORE_DATA_NEEDED':
      return { ...state, loading: false, value: action.query, results: [{title: "", description: "Please type at least 3 characters..."}] }  
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection }

    default:
      throw new Error()
  }
}  
const initialState = {
  loading: false,
  results: [],
  value: '',
}

const Bailiffs = ({}) => {

  const PAGE_SIZE = 10

  const { t } = useTranslation()
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate(`/`);
  }

  const { country } = useParams()

  const [countryByCode, setCountryByCode] = useState("")
  const [name, setName] = useState("")
  const [municipality, setMunicipality] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [bailiffs, setBailiffs] = useState([])
  const [bailiffsToDisplay, setBailiffsToDisplay] = useState([])
  // const [sortingFilter, setSortingFilter] = useState(null)
  // const [orderFilter, setOrderFilter] = useState(null)
  // ------------------------------------------------- SEARCH -----------------------------------------------------


  const [state, dispatch] = React.useReducer(exampleReducer, initialState)
  const timeoutRef = React.useRef() 

// ------------------------------------------------- SEARCH -----------------------------------------------------

  const sortingOptions = [
    {
      key: 'name', text: 'Name', value: 'name'
    },
    {
      key: 'municipality', text: 'Municipality', value: 'municipality'
    }
  ]

  const orderOptions = [
    {
      key: 'ascending', text: t('ascending'), value: 'ascending'
    },
    {
      key: 'descending', text: t('descending'), value: 'descending'
    }
  ]

  const buildPayload = () => {
    let payload = {}

      //
      if (name.length > 0) {
        payload["name"] = name
      }
      //
      if (municipality.length > 0) {
        payload["municipality"] = municipality
      }
      //
      if (postalCode.length > 0) {
        payload["postalCode"] = postalCode
      }
      //
      // if (sortingFilter != null && sortingFilter != ""){
      //   args.push(`sortBy=${sortingFilter}`)
      // }
      // //
      // if (orderFilter != null && orderFilter != ""){
      //   args.push(`orderBy=${orderFilter}`)
      // }
      //
      // const queryParams = payload.join('&')
    
      return payload;
  }
  const payload = useMemo(() => {
    return buildPayload()
  },[name, postalCode, municipality])


  const getCountryByCode = async (c) => {
   
    try {
      const allCountries = await default_client.get("/api/v1/countries"); 
      //
      const current = allCountries.data.find((element) => {
        return element.code === c;
      })      
      setCountryByCode(current);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCountryByCode(country)
  }, [country])
  //////////////////////////////////////////////////////////////////////////////


  const getBailiffs = async(c) => {
    try {
      const response = await getall(c);
      if (typeof response?.data !== 'undefined') {
        if (response?.data?.state === "answered" && response?.data?.competentBodies?.length > 0) {
          setBailiffs(response.data.competentBodies)
          setBailiffsToDisplay(response.data.competentBodies.slice(0, PAGE_SIZE))
        }
      }
      else {
        console.log("Bailiffs are not defined for " + c);
      }
     
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBailiffs(country)
  }, [country])

  //////////////////////////////////////////////////////////////////////////////

  const searchByFilters = async () => {
    try {
      let response;
      if (country === "cz" || country === "pt" || country === "lv" || country === "lt" || country === "hu" || country === "nl") {
        response = await default_client.post(`/api/v1/bailiffs/${country}`, payload)
      } 
      if (country === "gr") {
        response = await gr_client.post(`/api/search`, payload)
      } 
      if (country === "ee") {
        response = await ee_client.post(`/wp-json/getBailiffs/v2/getAllFiltered?lang=en`, payload)
      }     
      // endpoint = `/api/v1/bailiffs/${country}`
      // const response = await default_client.post(endpoint, payload)
      setBailiffs(response.data.competentBodies)
      setBailiffsToDisplay(response.data.competentBodies.slice(0, PAGE_SIZE))
    } catch (e) {
      console.log(e)
    }
  }

  const clearFilters = async () => {
    try {
      setName('')
      setMunicipality('')
      setPostalCode('')
      dispatch({ type: 'CLEAN_QUERY' }) // Search field
      //
      // setSortingFilter(null)
      // setOrderFilter(null)
      // reload all bailiffs 
      getBailiffs(country)
    } catch (e) {
      console.log(e)
    }
  }



  // const handleSearchChange = React.useCallback((e, data) => {
  //   if (data?.value?.length < 4) {
  //     dispatch({ type: 'MORE_DATA_NEEDED', query: data.value })
  //     return
  //   }
  //   clearTimeout(timeoutRef.current)
  //   dispatch({ type: 'START_SEARCH', query: data.value })
    
  //   timeoutRef.current = setTimeout(() => {
  //     if (data.value.length === 0) {
  //       dispatch({ type: 'CLEAN_QUERY' })
  //       return
  //     }

  //     const re = new RegExp(_.escapeRegExp(data.value), 'i')
  //     const isMatch = (result) => re.test(result.title)

  //     dispatch({
  //       type: 'FINISH_SEARCH',
  //       results: _.filter(isMatch),
  //     })
  //   }, 300)
  // }, [])
  // React.useEffect(() => {
  //   return () => {
  //     clearTimeout(timeoutRef.current)
  //   }
  // }, [])

  // const handleResultSelect = (e, { result }) => {
  //     dispatch({ type: 'UPDATE_SELECTION', selection: result.title })      
  // }
  
  const onNameChange = (e, data) => {
    setName(data.value)
  }

  const onMunicipalityChange = (e, data) => {
    setMunicipality(data.value)
  }

  const onPostalCodeChange = (e, data) => {
    setPostalCode(data.value)
  }

  const handlePaginationChange = (e, { activePage }) => {
    setBailiffsToDisplay(bailiffs.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE))
  }

  // const onSortingChange = (e, data) => {
  //   if (orderFilter == null){
  //     setOrderFilter("ascending");
  //   }
  //   setSortingFilter(data.value);
  // }

  // const onOrderChange = (e, data) => {
  //   setOrderFilter(data.value);
  // }

  const isFilterButtonDisabled = ()  => {
    return (
            name.length == 0 &&            
            municipality.length == 0 && 
            postalCode.length == 0 
            // && !sortingFilter
            // && !orderFilter
            );
  }  

  return (
    <Grid stackable>
      <Button style={{backgroundColor: 'transparent'}} onClick={navigateToHome}> <Icon name='home' /> {t('main.home')}</Button>
      <Grid.Row className="language-info">
        <Grid.Column textAlign="left" width={12}>
          <Header as='h4'>{t('searchIn')} <Fragment>&nbsp;</Fragment><Flag name={country.toLowerCase()}></Flag>{countryByCode.name}</Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width={4}>
          <Button size="small" primary onClick={navigateToHome}>{t('changeCountry')}</Button>
        </Grid.Column>
      </Grid.Row>
   
      <Grid.Row>
        <Grid.Column width={6} className="first-column">
        <SearchButtons disabled={isFilterButtonDisabled()} onSearch={searchByFilters} onReset={clearFilters}/> 
          {/* Filters */}
          <Header as='h3'>{t('refineResults')}</Header>
           
          <Form.Field>
            <label htmlFor="postalCode">{t('postalCode')}</label><br/>
            <Input type="text"
              id="postalCode"
              name="postalCode"
              fluid
              onChange={onPostalCodeChange}
              value={postalCode}
              placeholder={t('select.typeInPostalCode')}/>
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="name">{t('name')}</label><br/>
            <Input type="text"
              id="name"
              name="name"
              fluid
              onChange={onNameChange}
              value={name}
              placeholder={t('select.typeInName')}/>
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="municipality">{t('municipality')}</label><br/>
            <Input type="text"
              id="municipality"
              name="municipality"
              fluid
              onChange={onMunicipalityChange}
              value={municipality}
              placeholder={t('select.typeInMunicipality')}/>
          </Form.Field>
          <br/>

          <br/>
          {/* <br/><br/> */}
          {/* <Divider/> */}
          {/* <Form.Field>
            <label htmlFor="sortBy">{t('sortBy')}</label><br/>
            <Dropdown id="sortBy"
              placeholder={t('select.selectSortingParameter')}
              fluid
              selection
              onChange={onSortingChange}
              options={sortingOptions}
              value={sortingFilter}
            />
          </Form.Field>
          <br/>
          <Form.Field>
            <label htmlFor="orderBy">{t('order')}</label><br/>
            <Dropdown id="orderBy"
              placeholder={t('select.selectOrderParameter')}
              fluid
              selection
              onChange={onOrderChange}
              options={orderOptions}
              value={orderFilter}
            />
          </Form.Field> */}
          {/* <br/><br/> */}
          {/* <SearchButtons disabled={isFilterButtonDisabled()} onSearch={searchByFilters} onReset={clearFilters}/>  */}
        </Grid.Column>

        <Grid.Column width={10} className="second-column">
          {/* Results */}
          <Grid columns='two'>
          {( bailiffs.length > 0 /*&& orderFilter == null*/ ) &&
            <Grid.Row>
            <Message attached='bottom' info>
                <Icon name='hand point down'/>
                {t('defaultOrdering')} {countryByCode.name}
              </Message>
              <br/>
            </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column>              
                <Header as={'h4'}>{bailiffs.length} {bailiffs.length == 1? t('recordFound') : t('recordsFound')} 
                  <Header.Subheader>
                    {bailiffsToDisplay.length} {t('outOf')} {bailiffs.length}
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>  
          <br/>

          {bailiffs.length > 0 &&
            <BailiffsResults bailiffs={bailiffsToDisplay}/>
          }

          {bailiffs.length > 0 &&
            <Pagination
              defaultActivePage={1}
              pointing
              secondary
              onPageChange={handlePaginationChange}
              totalPages={Math.ceil(bailiffs.length / 10)}
            />
          }

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}



const BailiffsResults = ({ bailiffs }) => {

  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return bailiffs.map((bailiff, index) => (
    <div key={bailiff.id}>
      <Accordion styled>       
        <Accordion.Title active={activeIndex === index} index={index} onClick={handleClick}>
          <Icon name='dropdown'/>
          {bailiff.details[0].name}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index} className="expert-details">
         {/* Create two columns for information */}
          <Grid columns='two' stackable> 
            <Grid.Row>
              <Grid.Column width={16}>
                <List> 
                  <List.Item>
                    <List.Icon name='mail' />
                    <List.Content>
                      <a href={"mailto:" + bailiff.details[0].email}>{bailiff.details[0].email !== null ? bailiff.details[0].email : "-"}</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='phone' />
                    <List.Content>
                      {bailiff.details[0].tel !== null ? bailiff.details[0].tel : "-"}
                    </List.Content>
                  </List.Item> 
                  <List.Item>
                    <List.Icon name='world' />
                    <List.Content>
                      {bailiff.details[0].url !== null ? bailiff.details[0].url : "-"}
                    </List.Content>
                  </List.Item>                                   
                  <List.Item>
                    <List.Icon name='marker' />
                    <List.Content>{bailiff.details[0].address}, {bailiff.details[0].postalCode}, {bailiff.details[0].municipality}</List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              {/* <Grid.Column width={6}>
                <List>
                  <List.Item>
                    <List.Header>{t('spokenLanguages')}</List.Header>
                    <List.Content></List.Content> 
                  </List.Item>
                </List>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>        

          {/* <Divider horizontal/> */}
                 
        </Accordion.Content>
      </Accordion>
      <br />
    </div>
  ))
}

export default Bailiffs
