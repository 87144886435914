import React from "react";
import { useTranslation} from 'react-i18next';
import { Button } from 'semantic-ui-react'

const SearchButtons = ({disabled, onSearch, onReset}) => {
  
    const {t} = useTranslation();

    
    return (
    <div>
      <Button disabled={disabled}
                  primary onClick={onSearch}>{t('filterResults')}</Button>
      <Button style={{backgroundColor: 'transparent'}} className="reset-btn" onClick={onReset}>{t('resetFilters')}</Button>
    </div>
    )
}

export default SearchButtons
