import axios from "axios";

export const default_client = axios.create({
    baseURL:'https://fab3.it.auth.gr/fab3',
    headers: { }
});

export const gr_client = axios.create({
    baseURL: 'https://portal.odee.gr',
})

export const ee_client = axios.create({
    baseURL: 'https://kpkoda.ee',
})

export const getall = async (country) => { 
    if (country === "cz" ||  country === "pt" ||  country === "lv" || country === "lt" || country === "hu" || country === "nl") {
        return await default_client.get(`/api/v1/bailiffs/${country}/getall`)
    } 
    if (country === "gr") {
        return await gr_client.get(`/api/getall`)
    } 
    if (country === "ee") {
        return await ee_client.get(`/wp-json/getBailiffs/v2/getAll?lang=en`)
    }     

}
